import styled from 'styled-components';
import Headline from "../../atoms/Headline/Headline.jsx";
import {Link, Navigate, useParams} from "react-router-dom";
import Text from "../../atoms/Text/Text.jsx";
import FilterNav from "../../molecules/FilterNav/FilterNav.jsx";
import Content from "../../atoms/Content/Content.jsx";
import ButtonRow from "../../atoms/ButtonRow/ButtonRow.jsx";
import media from "../../../utilities/media.js";
import Button from "../../atoms/Button/Button.jsx";
import React, {useContext} from "react";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";
import TextInput from "../../atoms/TextInput/TextInput.jsx";
import TextArea from "../../atoms/TextArea/TextArea.jsx";


const FilterContent = styled.div`
  ${media.moreThan("lg")} {
    padding-top: 60px;
  }
`;

const FilterPageStyled = styled.div`
  ${media.moreThan("lg")} {
    display: flex;
    flex-direction: column;

    ${FilterContent} {
      order: 2;
    }
  }
`

const FilterPage = ({finish}) => {
  const { state, dispatch } = useContext(AppContext);
  const {lang} = useParams();
  const [loading, setLoading] = React.useState(false);

  const config = state.config[lang];

  if (!state.selectedFilter) {
    return <div></div>;
  }

  return <FilterPageStyled>
    {!state.image && <Navigate to={`/${lang}/camera`}/>}
    <FilterContent>
      <FilterNav
        filters={config.filters}
      />
    </FilterContent>

    <Content>
      <Headline>{ config.FilterTitle }</Headline>
      <Text content={config.FilterContent} />
      {state.selectedFilter.textPosX ? (<div>
        <TextArea
          value={state.filterText}
          rows={4}
          onChange={e =>
            dispatch({type: 'SET_FILTER_TEXT', payload: e.target.value})
        }/>
      </div>) : null}
    </Content>
    <ButtonRow>
      <Button onClick={() => {
        setLoading(true);
        return finish();
      }} primary loading={loading}>{config.ButtonFinish}</Button>
    </ButtonRow>
  </FilterPageStyled>
}

export default FilterPage;
