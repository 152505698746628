import styled from "styled-components";
import {inputtext} from "../../../styles/typography.js";

const TextInput = styled.input`
  ${inputtext};
  width: 100%;
  max-width: 300px;
  padding: 5px 10px;
`;

export default TextInput;
