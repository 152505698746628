import {useState} from "react";
import {useForm} from "react-hook-form";

import styled from "styled-components";

import Text from "../../atoms/Text/Text.jsx";
import Button from "../../atoms/Button/Button.jsx";
import ButtonRow from "../../atoms/ButtonRow/ButtonRow.jsx";
import Checkbox from "../../atoms/Checkbox/Checkbox.jsx";
import {base_url} from "../../../App.jsx";
import Headline from "../../atoms/Headline/Headline.jsx";
import FancyCheckbox from "../../atoms/FancyCheckbox/FancyCheckbox.jsx";
import TextInput from "../../atoms/TextInput/TextInput.jsx";
import TextArea from "../../atoms/TextArea/TextArea.jsx";

const RegistrationFormStyled = styled.div`
  position: relative;
`;

const RegistrationFormMessage = styled.div`
  position: absolute;
  padding: 20px 0;
  opacity: ${props => props.sent ? 1 : 0};
  transition: opacity 500ms ease-out;
  pointer-events: none;
`;

const FormRow = styled.div`
  padding: 5px 0;
`;

const Form = styled.form`
  margin-bottom: 40px;
  opacity: ${props => props.sent ? 0 : 1};
  transition: opacity 500ms ease-out;
`;

const RegistrationForm = ({config, hash, registrationActive}) => {
  const {register, handleSubmit, watch, formState: {errors}} = useForm();

  const [conditions, setConditions] = useState(false);
  const [sent, setSent] = useState(false);
  const [checked, setChecked] = useState(false);

  const onSubmit = data => {
    if (!conditions) {
      return;
    }
    const form = new FormData();
    form.append("Name", data.Name);
    form.append("Email", data.Email);
    form.append("Message", data.Message);
    form.append("Hash", hash);
    fetch(`${base_url}/api/RegistrationForm`, {
      method: 'POST',
      body: form,
    }).then(() => {
      setSent(true);
    });
  }

  return (
    <RegistrationFormStyled>
      <RegistrationFormMessage sent={sent}>
        <Headline>{config.RegistrationThankYouTitle}</Headline>
        <Text content={config.RegistrationThankYouContent}/>
      </RegistrationFormMessage>
      <Form onSubmit={handleSubmit(onSubmit)} sent={sent}>
        {registrationActive ? (
          <>
            <FormRow>
              <TextInput type="text"
                         placeholder={config.RegistrationNameTitle} {...register("Name", {required: true})} />
            </FormRow>
            <FormRow>
              <TextInput type="email"
                         placeholder={config.RegistrationEmailTitle} {...register("Email", {required: true})} />
            </FormRow>
            <FormRow>
              <TextArea placeholder={config.RegistrationMessageTitle} {...register("Message", {required: false})} />
            </FormRow>
            <FormRow>
              <Checkbox
                name="conditions"
                checked={conditions}
                onChange={(e) => setConditions(e.target.checked)}
                label={config.HomeDataProtection}
              />
            </FormRow>
          </>
        ) : null}
        <FormRow>
          <FancyCheckbox
            name="permission"
            checked={checked}
            onChange={e => {
              const form = new FormData();
              if (!checked)
                form.append("Permission", !checked);
              form.append("Hash", hash);
              fetch(`${base_url}/api/PermissionForm`, {
                method: 'POST',
                body: form,
              }).then(() => {
                setChecked(!checked);
              });
            }}
            label={config.PermissionText}
          />
        </FormRow>
        {registrationActive ? (
          <ButtonRow>
            <Button onClick={(e) => {
              e.preventDefault();
            }} primary>{config.RegistrationFormButton}</Button>
          </ButtonRow>
        ) : null}
      </Form>
    </RegistrationFormStyled>
  )
}

export default RegistrationForm;
