import styled from 'styled-components';
import Headline from "../../atoms/Headline/Headline.jsx";
import Text from "../../atoms/Text/Text.jsx";
import Content from "../../atoms/Content/Content.jsx";
import {useContext, useEffect} from "react";
import ButtonRow from "../../atoms/ButtonRow/ButtonRow.jsx";
import UploadButton from "../../atoms/UploadButton/UploadButton";
import {useNavigate, useParams} from "react-router-dom";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";
import createIsolatedImage from "../../../utilities/createIsolatedImage/createIsolatedImage.js";


const CameraPageStyled = styled.div`
`

const CameraPage = () => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    if(state.image) {
      dispatch({ type: 'SET_IMAGE', payload: null })
    }
  }, []);

  const config = state.config[lang];

  return <CameraPageStyled>
    <Content>
      <Headline>{ config.CameraTitle }</Headline>
      <Text content={config.CameraContent} />
    </Content>
    <ButtonRow>
      <UploadButton onChange={(image) => {
        dispatch({ type: 'SET_IMAGE', payload: image });
        createIsolatedImage(image, (data) => {
          dispatch({type: 'SET_ISOLATED_IMAGE', payload: `data:image/png;base64,${data.data.result_b64}`})
        });
        navigate(`/${lang}/filter`);
      }} title={ config.ButtonUpload } lang={lang}/>
    </ButtonRow>
  </CameraPageStyled>
}

export default CameraPage;
