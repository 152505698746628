import {BrowserRouter, Link, Navigate, Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import {v4 as uuidv4} from 'uuid';
import styled from 'styled-components';
import Layout from "./components/organisms/Layout/Layout.jsx";
import SlideRoutes from 'react-slide-routes';

import GlobalStyle from '../src/styles/globals';
import {ThemeProvider} from "styled-components";
import defaultTheme from "./styles/defaultTheme.js";
import CameraPage from "./components/pages/CameraPage/CameraPage";
import HomePage from "./components/pages/HomePage/HomePage";
import FilterPage from "./components/pages/FilterPage/FilterPage.jsx";
import Camera from "./components/molecules/Camera/Camera.jsx";
import Placeholder from "./components/molecules/Placeholder/Placeholder.jsx";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import React, {forwardRef, useContext, useEffect, useRef, useState} from "react";
import FinishedPage from "./components/pages/FinishedPage/FinishedPage.jsx";
import FinishedImage from "./components/molecules/FinishedImage/FinishedImage.jsx";
import AppContext, {AppContextProvider} from "./utilities/AppContext/AppContext.jsx";

export const base_url = import.meta.env.DEV ? "http://tkselfie.test" : "";

const PreviewContainer = styled.div`
  &.fade-enter,
  &.fade-exit {
    transition: opacity 500ms ease-out;
  }

  &.fade-enter {
    opacity: 0.01;
  }

  &.fade-enter.fade-enter-active {
    opacity: 1
  }

  &.fade-exit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 1;
    z-index: 10;
  }

  &.fade-exit.fade-exit-active {
    opacity: 0;
  }
`;

const Preview = forwardRef(({finish, entry, urlhash, lang}, cropperRef) => {
  const location = useLocation();
  const {state, dispatch} = useContext(AppContext);

  const camera = <Camera
    ref={cropperRef}
  />;

  return <TransitionGroup>
    <CSSTransition key={location.key} classNames="fade" timeout={1000}>
      <PreviewContainer>
        <Routes location={location}>
          <Route path="/:lang/" element={<Placeholder entry={entry}/>}/>
          <Route path="/:lang/camera" element={camera}/>
          <Route path="/:lang/filter" element={camera}/>
          <Route path="/:lang/finish" element={
            <FinishedImage image={state.editedImage} urlhash={urlhash} lang={lang}/>
          }/>
        </Routes>
      </PreviewContainer>
    </CSSTransition>
  </TransitionGroup>;
})

const Main = ({urlhash}) => {
  const lang = location.pathname.split('/')[1];
  const cropperRef = useRef(null);
  const [hash, setHash] = useState('');
  const {state, dispatch} = useContext(AppContext);

  const navigate = useNavigate();

  const url = urlhash ? `${base_url}/api/conf/?id=${urlhash}` : `${base_url}/api/conf/`;

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((config) => {
        dispatch({type: "SET_CONFIG", payload: config});
      });
  }, [])


  useEffect(() => {
    if (!state.selectedFilter && state.config && lang && state.config[lang].filters) {
      dispatch({type: 'SET_SELECTED_FILTER', payload: state.config[lang].filters[0]})
    }
  }, [state.config, lang]);

  if (!state.config) {
    return <div></div>;
  }

  const loadImages = (sources, callback) => {
    const images = {};
    let loadedImages = 0;
    const numImages = sources.length;

    for (let src in sources) {
      const index = sources[src];
      images[index] = new Image();
      images[index].setAttribute('crossorigin', 'anonymous');
      images[index].onload = function () {
        if (++loadedImages >= numImages) {
          callback(images);
        }
      };
      images[index].src = sources[src];
    }
  }

  const finish = () => {
    const {cropper, text} = cropperRef.current;

    const canvas = cropper.getCanvas();
    const textCanvas = text ? text : null;

    if (canvas) {
      const newCanvas = document.createElement('canvas');
      const context = newCanvas.getContext('2d');

      const width = Math.min(canvas.width, 1000);
      const height = Math.min(canvas.height, 1000);

      //set dimensions
      newCanvas.width = width;
      newCanvas.height = height;

      //load images
      const sources = [state.selectedFilter.image];

      if (state.selectedFilter.bgimage) {
        sources.push(state.selectedFilter.bgimage);
      }

      loadImages(sources, (images) => {
        // bgimage
        if (state.selectedFilter.bgimage) {
          context.drawImage(images[state.selectedFilter.bgimage], 0, 0, width, height);
        }

        // photo
        context.drawImage(canvas, 0, 0, width, height);

        // overlay
        context.drawImage(images[state.selectedFilter.image], 0, 0, width, height);

        // text
        if (textCanvas) {
          context.drawImage(textCanvas, 0, 0, width, height);
        }

        dispatch({type: 'SET_EDITED_IMAGE', payload: newCanvas.toDataURL()});

        const newhash = uuidv4();
        setHash(newhash);

        const form = new FormData();
        form.append("Hash", newhash);
        form.append("action_submit", "Submit");
        newCanvas.toBlob((blob) => {
          if (blob) {
            form.append('Image', blob, `${newhash}.jpg`);
            fetch(`${base_url}/api/EntryForm`, {
              method: 'POST',
              body: form,
            }).then(() => {
              navigate(`/${lang}/finish?id=${newhash}`);
            });
          }
        }, 'image/jpeg');
      });
    }
  }

  return (
    <Layout
      lang={lang}
      preview={
        <Preview
          finish={finish}
          ref={cropperRef}
          entry={state.config.entry}
          urlhash={urlhash}
          lang={lang}
        />
      }
    >
      <SlideRoutes>
        <Route path="/:lang/" element={
          <HomePage lang={lang}/>
        }/>
        <Route path="/:lang/camera" element={
          <CameraPage
            finish={finish}
          />
        }/>
        <Route path="/:lang/filter" element={
          <FilterPage
            finish={finish}
          />
        }/>
        <Route path="/:lang/finish" element={
          <FinishedPage hash={hash}/>
        }/>
        <Route path="/" element={
          <Navigate to="/de/"/>
        }/>
      </SlideRoutes>
    </Layout>
  )
}

const App = () => {
  const search = /id=([^&]+)/.exec(location.href);
  const id = search ? search[1] : null;

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppContextProvider>
        <GlobalStyle/>
        <BrowserRouter>
          <Main urlhash={id}/>
        </BrowserRouter>
      </AppContextProvider>
    </ThemeProvider>
  )
}

export default App;
