const createIsolatedImage = (image, callback) => {

  if (!image)
    return;

  const api_key = import.meta.env.VITE_REMOVEBG_API_KEY;
  const api_url = api_key ? 'https://api.remove.bg/v1.0/removebg' : 'http://tkselfie.test/fake_response.json';
  // const api_url = 'http://tkselfie.test/fake_response.json';

  const form = new FormData();
  form.append('image_file_b64', image);
  form.append('type', 'person');
  form.append('size', 'full');
  form.append('format', 'auto');

  const headers = api_key ? {
    'Accept': 'application/json',
    'X-Api-Key': api_key,
  } : {};

  fetch(api_url, {
    method: 'POST',
    headers,
    body: form,
  }).then((response) => {
    return response.json();
  }).then((data) => {
    callback(data);
  });
}

export default createIsolatedImage;
