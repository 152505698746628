import styled from 'styled-components';
import { FixedCropper, ImageRestriction } from 'react-advanced-cropper'
import Square from "../../atoms/Square/Square.jsx";
import 'react-advanced-cropper/dist/style.css';

import Overlay from "../../atoms/Overlay/Overlay.jsx";

import {forwardRef, useContext, useEffect, useRef, useState} from "react";
import Background from "../../atoms/Background/Background.jsx";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";
import TextCanvas from "../../atoms/TextCanvas/TextCanvas.jsx";
import {useParams} from "react-router-dom";

const PreviewStyled = styled.div`
  display: flex;
  width: 100%;

  .react-transform-wrapper {
    height: 100%;
  }

  .advanced-cropper {
    height: 100%;
    background: transparent;
  }
`

const Preview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ImageEditor = forwardRef(({ zoom }, ref) => {
  const { lang } = useParams();
  const { state, dispatch } = useContext(AppContext);
  const [isReady, setIsReady] = useState(false);

  const stencilSize = ({ boundary }) => {
    return {
      width: boundary.width,
      height: boundary.height,
    };
  };

  const defaultSize = ({ imageSize }) => {
    const size = Math.min(imageSize.width, imageSize.height);
    return {
      width: size,
      height: size,
    };
  };

  const onChange = (cropper) => {
    // console.log('onChange', cropper.getState());
    dispatch({ type: 'SET_CROPPER_STATE', payload: cropper.getState() });
    const cropperImage = cropper.getImage()
    dispatch({ type: 'SET_CROPPER_IMAGE', payload: {
      ...cropperImage,
      src: state.image,
    } });
    dispatch({ type: 'SET_ISOLATED_CROPPER_IMAGE', payload: {
      ...cropperImage,
      src: state.isolatedImage,
    } });
    dispatch({ type: 'SET_CROPPER_TRANSITIONS', payload: cropper.getTransitions() });
  };

  const localRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    // Manually set ref to fix empty ref bug
    ref.current = {
      cropper: localRef.current,
      text: textRef.current,
    };
  }, [localRef.current, textRef.current]);

  if(!(state.image && state.isolatedImage))
    return <div></div>;

  return <PreviewStyled test={isReady}>
    <Square>
      {state.selectedFilter.bgimage ? <Background src={state.selectedFilter.bgimage}/> : null}
      <FixedCropper
        src={state.selectedFilter.bgimage ? state.isolatedImage : state.image}
        defaultSize={defaultSize}
        stencilProps={{
          handlers: false,
          lines: false,
          movable: false,
          resizable: false,
        }}
        stencilSize={stencilSize}
        imageRestriction={ImageRestriction.stencil}
        sizeRestrictions={{
          // minWidth: 1000,
        }}
        onChange={onChange}
        ref={localRef}
      />
      {state.selectedFilter.textPosX ? (
        <TextCanvas
          text={state.filterText || ''}
          x={state.selectedFilter.textPosX}
          y={state.selectedFilter.textPosY}
          width={state.selectedFilter.textWidth}
          height={state.selectedFilter.textHeight}
          fontSize={state.selectedFilter.textSize}
          lineHeight={state.selectedFilter.textLineHeight}
          color={state.selectedFilter.textColor}
          ref={textRef}
        />
      ) : null}
      <Overlay src={state.selectedFilter.image}/>
    </Square>
  </PreviewStyled>;
});

export default ImageEditor;
