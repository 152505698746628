import styled from 'styled-components';

const BackgroundStyled = styled.img`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
`

const Background = ({ src, alt }) => {
  return <BackgroundStyled src={src} alt={alt}/>;
}

export default Background;
