import styled from "styled-components";
import {inputtext} from "../../../styles/typography.js";

const TextArea = styled.textarea`
  ${inputtext};
  width: 100%;
  max-width: 300px;
  padding: 5px 10px;
`;

export default TextArea;
