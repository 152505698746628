import styled, {css, keyframes} from 'styled-components';
import Webcam from "react-webcam";
import React, {forwardRef, useContext, useRef} from "react";
import Square from "../../atoms/Square/Square.jsx";
import {useNavigate, useParams} from "react-router-dom";
import ImageEditor from "../ImageEditor/ImageEditor.jsx";
import CameraButton from "../../atoms/CameraButton/CameraButton.jsx";
import Select from "../../atoms/Select/Select.jsx";
import media from "../../../utilities/media.js";
import CameraButtonRow from "../../atoms/CameraButtonRow/CameraButtonRow.jsx";
import {copytext} from "../../../styles/typography.js";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";
import createIsolatedImage from "../../../utilities/createIsolatedImage/createIsolatedImage.js";

const CameraStyled = styled.div`
  width: 100%;
  margin-bottom: 20px;

  ${media.moreThan("lg")} {
    margin-bottom: 50px;
  }
`

const WebcamStyled = styled(Webcam)`
  width: 100%;
  height: 100%;
  //object-fit: cover;
`;

const Recording = styled.div`
  width: 100%;
  position: relative;
`;

const Preview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const flash = keyframes`
  0% {
    opacity: 1;
  }
  1% {
    opacity: 1
  }
  100% {
    opacity: 0;
  }
`

const Flash = styled(Square)`
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 100;
  pointer-events: none;

  ${props => props.image && css`
    animation: ${flash} 1.5s ease-in-out;
  `}
`;

const CameraSelectorContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const NoPermission = styled.div`
  ${copytext};
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const BelowCamera = styled.div`
  margin-top: 40px;

  ${media.lessThan("lg")} {
    display: none;
  }
`;

const Camera = forwardRef((
  {}, cropperRef) => {
  const { state, dispatch } = useContext(AppContext);
  const [devices, setDevices] = React.useState([]);
  const [mediaPermission, setMediaPermission] = React.useState(false);

  const navigate = useNavigate();
  const {lang} = useParams();

  const handleDevices = React.useCallback(
    mediaDevices => {
      const devices = mediaDevices.filter(({kind}) => kind === "videoinput")
      setDevices(devices);
      if (!state.selectedDeviceId && devices.length > 0) {
        dispatch({ type: 'SET_SELECTED_DEVICE_ID', payload: devices[0].deviceId })
      }
    },
    [setDevices]
  );



  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    () => {
      const image = webcamRef.current.getScreenshot()
      dispatch({ type: 'SET_IMAGE', payload: image })
      createIsolatedImage(image, (data) => {
        dispatch({type: 'SET_ISOLATED_IMAGE', payload: `data:image/png;base64,${data.data.result_b64}`})
      });
      navigate(`/${lang}/filter`);
    },
    [webcamRef]
  );

  React.useEffect(() => {
    if (!navigator.mediaDevices?.getUserMedia) {
      setMediaPermission(false);
      return;
    }
    navigator.mediaDevices.getUserMedia({
      video: true,
    }).then(() => {
      setMediaPermission(true);
    });
  }, [])

  React.useEffect(
    () => {
      if (!navigator.mediaDevices?.enumerateDevices) {
        setDevices([]);
        dispatch({ type: 'SET_SELECTED_DEVICE_ID', payload: null })
        return;
      }
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    },
    [handleDevices, mediaPermission]
  );

  const videoConstraints = {
    // facingMode: "user",
      // height: 480,
      // width: 480,
    deviceId: state.selectedDeviceId,
    aspectRatio: 1,
  };

  const repeatPermission = () => {
    navigator.mediaDevices.getUserMedia({
      video: true,
    }).then(() => {
      setMediaPermission(true);
    });
  }

  return <CameraStyled>
    <Flash image={state.image}/>
    {state.image ? (
      <div>
        <Square background="black">
          <ImageEditor image={state.mage} ref={cropperRef}/>
          <CameraButtonRow>
            <CameraButton onClick={() => {
              if (confirm(state.config.DiscardConfirmation)) {
                dispatch({ type: 'SET_IMAGE', payload: null })
              }
            }} icon="repeat"/>
            <CameraButton onClick={() => cropperRef.current.cropper.zoomImage(0.8)} icon="minus"/>
            <CameraButton onClick={() => cropperRef.current.cropper.zoomImage(1.2)} icon="plus"/>
          </CameraButtonRow>
        </Square>
      </div>
    ) : (
      <Recording>
        <Square background="black">
          {mediaPermission ? (
            <>
              <WebcamStyled
                videoConstraints={videoConstraints}
                ref={webcamRef}
                mirrored
                screenshotFormat="image/jpeg"
                onClick={capture}
                minScreenshotHeight={1280}
                minScreenshotWidth={1280}
                height={720}
                width={720}
              />
            </>
          ) : (
            <NoPermission onClick={() => repeatPermission()}>
              Keine Erlaubnis erteilt.
            </NoPermission>
          )}
        </Square>
        {devices.length > 1 && (
          <CameraSelectorContainer>
            <Select
              onChange={(event) => dispatch({ type: 'SET_SELECTED_DEVICE_ID', payload: event.target.value })}
              value={state.selectedDeviceId}
            >
              {devices.map((device, key) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label || `Device ${key + 1}`}
                </option>))}
            </Select>
          </CameraSelectorContainer>
        )}
        {mediaPermission && (
          <CameraButtonRow>
            <CameraButton onClick={capture} icon="camera"/>
          </CameraButtonRow>
        )}
      </Recording>
    )}
  </CameraStyled>
})

export default Camera;
