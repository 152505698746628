import styled from 'styled-components';
import Headline from "../../atoms/Headline/Headline.jsx";
import Text from "../../atoms/Text/Text.jsx";
import Content from "../../atoms/Content/Content.jsx";
import ButtonRow from "../../atoms/ButtonRow/ButtonRow.jsx";
import ButtonLink from "../../atoms/ButtonLink/ButtonLink.jsx";
import {base_url} from "../../../App.jsx";
import ButtonHref from "../../atoms/ButtonHref/ButtonHref.jsx";
import ButtonShare from "../../atoms/ButtonShare/ButtonShare.jsx";
import {useNavigate, useParams} from "react-router-dom";
import Button from "../../atoms/Button/Button.jsx";
import Checkbox from "../../atoms/Checkbox/Checkbox.jsx";
import FancyCheckbox from "../../atoms/FancyCheckbox/FancyCheckbox.jsx";
import {useContext, useState} from "react";
import RegistrationForm from "../../molecules/RegistrationForm/RegistrationForm.jsx";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";


const FinishedPageStyled = styled.div`
`

const FinishedPage = ({ hash }) => {
  const { state, dispatch } = useContext(AppContext);
  const { lang } = useParams();
  const navigate = useNavigate();
  const frontend_url = location.protocol + '//' + location.host;

  const [checked, setChecked] = useState(false);

  const config = state.config[lang];

  const restart = () => {
    if (confirm(config.DiscardConfirmation)) {
      navigate(`/${lang}/camera`);
    }
  }

  return <FinishedPageStyled>
    <Content>
      <Headline>{ config.FinishTitle }</Headline>
      <Text content={ config.FinishContent } />
      <RegistrationForm hash={hash} config={config} registrationActive={config.RegistrationActive}/>
      {/*<FancyCheckbox*/}
      {/*  name="permission"*/}
      {/*  checked={checked}*/}
      {/*  onChange={e => {*/}
      {/*    const form = new FormData();*/}
      {/*    if(!checked)*/}
      {/*      form.append("Permission", !checked);*/}
      {/*    form.append("Hash", hash);*/}
      {/*    fetch(`${base_url}/api/PermissionForm`, {*/}
      {/*      method: 'POST',*/}
      {/*      body: form,*/}
      {/*    }).then(() => {*/}
      {/*      setChecked(!checked);*/}
      {/*    });*/}
      {/*  }}*/}
      {/*  label={config.PermissionText}*/}
      {/*/>*/}
    </Content>
    <ButtonRow>
      <ButtonHref href={`${base_url}/assets/Uploads/${hash}.jpg`} download={`${hash}.jpg`}>{config.ButtonDownload}</ButtonHref>
      <Button onClick={restart}>{config.ButtonRestart}</Button>
      <ButtonShare link={`${frontend_url}/${lang}?id=${hash}`} title={config.SharingTitle} text={config.SharingDescription}>{config.ButtonShare}</ButtonShare>
    </ButtonRow>
    <ButtonRow>

    </ButtonRow>
  </FinishedPageStyled>
}

export default FinishedPage;
