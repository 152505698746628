import styled from "styled-components";
import {forwardRef, useEffect, useRef} from "react";
import {drawText} from "canvas-txt";
import {base_url} from "../../../App.jsx";

const TextCanvasStyled = styled.canvas`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
`;

const TextCanvas = forwardRef((
  {
    text,
    x,
    y,
    width,
    height,
    fontSize,
    lineHeight,
    color
  }, ref) => {

  // const localRef = useRef(null);

  useEffect(() => {
    const canvas = ref.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const myFont = new FontFace(
      'TKType',
      `url(${base_url}/tktyperegular-regular.woff2)`
    );

    myFont.load().then((font) => {
      document.fonts.add(font);
      ctx.fillStyle = color;
      const { textHeight } = drawText(ctx, text, {
        x: x * 10,
        y: y * 10,
        width: width * 10,
        height: height * 10,
        font: 'TKType',
        fontSize: fontSize,
        lineHeight: lineHeight,
        align: 'left',
        vAlign: 'top',
      })
    });
  }, [text, x, y, width, height, fontSize, lineHeight, color]);

  return (
    <TextCanvasStyled width={1000} height={1000} ref={ref}/>
  );
});

export default TextCanvas;
