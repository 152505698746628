import styled from 'styled-components';
import {CropperPreview} from "react-advanced-cropper";
import Overlay from "../Overlay/Overlay.jsx";
import media from "../../../utilities/media.js";
import Background from "../Background/Background.jsx";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";
import {useContext} from "react";

const FilterPreviewStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  img {
    display: block;
  }
`

const CropperPreviewStyled = styled(CropperPreview)`
  width: 100%;
  height: 100%;
`;

const FilterPreview = ({ filter }) => {
  const { state, dispatch } = useContext(AppContext);

  return <FilterPreviewStyled>
    <Background src={filter.bgimage}/>
    <CropperPreviewStyled
      className="preview"
      image={filter.bgimage ? state.isolatedCropperImage : state.cropperImage}
      state={state.cropperState}
      transitions={state.cropperTransitions}
    />
    <Overlay src={filter.image} />
  </FilterPreviewStyled>
}

export default FilterPreview;
