import styled from 'styled-components';
import Headline from "../../atoms/Headline/Headline.jsx";
import Text from "../../atoms/Text/Text.jsx";
import Content from "../../atoms/Content/Content.jsx";
import ButtonRow from "../../atoms/ButtonRow/ButtonRow.jsx";
import Checkbox from "../../atoms/Checkbox/Checkbox.jsx";
import ButtonLink from "../../atoms/ButtonLink/ButtonLink.jsx";
import {useParams} from "react-router-dom";
import {QRCodeSVG} from 'qrcode.react';

import qrde from './qr-de.svg';
import qren from './qr-en.svg';
import media from "../../../utilities/media.js";
import Subline from "../../atoms/Subline/Subline.jsx";
import {useContext} from "react";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";


const HomePageStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  padding-bottom: 20px;

  ${media.moreThan("lg")} {
    padding-bottom: 50px;
  }
`

const QRSection = styled.div`
  margin-top: auto;
  display: none;

  ${media.moreThan("lg")} {
    display: flex;
    align-items: flex-start;
  }
`;

const QRImageContainer = styled.div`
  margin-right: 20px;
  //overflow: hidden;
  width: 100px;
  background-color: #fff;
  padding: 5px;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }

`;

const QRImage = styled.img`
  display: block;
  width: 110%;
  margin-left: -5%;
  margin-top: -5%;
`;

const QRSectionText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    margin-bottom: 0;
  }
`;

const HomePage = () => {
  const { lang } = useParams();
  const { state, dispatch } = useContext(AppContext);

  const config = state.config[lang];

  return <HomePageStyled>
    <Content>
      <Headline>{config.HomeTitle.split('\n').map((item, key) => {
  return <span key={key}>{item}<br/></span>
})}</Headline>
      {config.HomeSubline && <Subline>{config.HomeSubline}</Subline>}
      <Text content={config.HomeContent} />
    </Content>
    <ButtonRow>
      <Checkbox
        name="conditions"
        checked={state.conditions}
        onChange={(e) => dispatch({ type: "SET_CONDITIONS", payload: e.target.checked })}
        label={config.HomeDataProtection}
      />
      <ButtonLink to={`/${lang}/camera`} disabled={!state.conditions}>{config.ButtonContinue}</ButtonLink>
    </ButtonRow>
    <QRSection>
      <QRImageContainer>
        <QRCodeSVG value={window.location.href} />
      </QRImageContainer>
      <QRSectionText>
        <Text content={config.HomeQRCodeText} />
      </QRSectionText>
    </QRSection>
  </HomePageStyled>
}

export default HomePage;
