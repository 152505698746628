const appReducer = (state, action) => {
  switch (action.type) {
    case "SET_CONDITIONS":
      return {...state, conditions: action.payload};
    case "SET_CONFIG":
      return {...state, config: action.payload, configLoaded: true};
    case "SET_IMAGE":
      return {...state, image: action.payload};
    case "SET_ISOLATED_IMAGE":
      return {...state, isolatedImage: action.payload};
    case "SET_CROPPER_STATE":
      return {...state, cropperState: action.payload};
    case "SET_CROPPER_IMAGE":
      return {...state, cropperImage: action.payload};
    case "SET_ISOLATED_CROPPER_IMAGE":
      return {...state, isolatedCropperImage: action.payload};
    case "SET_CROPPER_TRANSITIONS":
      return {...state, cropperTransitions: action.payload};
    case "SET_SELECTED_FILTER":
      const selectedFilter = action.payload;
      // If defaultText not changed, take new defaultText
      const filterText = (!state.selectedFilter
        || state.selectedFilter.defaultText === state.filterText)
        ? selectedFilter.defaultText
        : state.filterText;
      return {...state, selectedFilter, filterText};
    case "SET_SELECTED_DEVICE_ID":
      return {...state, selectedDeviceId: action.payload};
    case "SET_EDITED_IMAGE":
      return {...state, editedImage: action.payload};
    case "SET_FILTER_TEXT":
      return {...state, filterText: action.payload};
    default:
      return state;
  }
}

export default appReducer;
